import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import './ApplyForLoan.css';

const ApplyForLoan = () => {
  const [loanType, setLoanType] = useState('');
  const [interestRate] = useState(12); // Fixed interest rate
  const [duration, setDuration] = useState('');
  const [amount, setAmount] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [aadharCard, setAadharCard] = useState(null);
  const [installmentType, setInstallmentType] = useState('');
  const [guardianName, setGuardianName] = useState(''); // New field
  const [panNo, setPanNo] = useState(''); // New field
  const [bankAcNo, setBankAcNo] = useState(''); // New field
  const [nominiee, setNominiee] = useState(''); // New field
  const [relationOfNominiee, setRelationOfNominiee] = useState(''); // New field
  const [dateOfBirth, setDateOfBirth] = useState(''); // New field
  const [profilePicture, setProfilePicture] = useState(null); // New field
  const [Granter ,setGranter] = useState(null); // New field
  const [profilePicturePreview, setProfilePicturePreview] = useState(null); // New field
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loanId, setLoanId] = useState(null);

  const navigate = useNavigate();
  const userId = parseInt(localStorage.getItem('shristi_user_id'), 10);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('loan_type', loanType);
    formData.append('interest_rate', interestRate);
    formData.append('duration', parseInt(duration, 10));
    formData.append('user_id', userId);
    formData.append('branch_id', 1); // Replace with actual branch ID
    formData.append('amount', parseFloat(amount));
    formData.append('approval_status', 'pending');
    formData.append('posted_at', new Date().toISOString().split('T')[0]);
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('address', address);
    formData.append('pincode', pincode);
    formData.append('aadhar_card', aadharCard);
    formData.append('installment_type', installmentType);
    formData.append('collector_assigned', 1);
    formData.append('guardian_name', guardianName);
    formData.append('pan_no', panNo);
    formData.append('bank_ac_no', bankAcNo);
    formData.append('nominiee', nominiee);
    formData.append('relation_of_nominiee', relationOfNominiee);
    formData.append('date_of_birth', dateOfBirth);
    formData.append('profile_picture', profilePicture);
    formData.append('granter', Granter);

    try {
      const response = await fetch('https://srishticooperativesociety.com/api/loan_requests', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit loan application');
      }

      const responseData = await response.json();
      setLoanId(responseData.id);
      setSuccessMessage(`Your loan ID is ${responseData.id}. Your application is successfully posted for approval.`);
      setLoanType('');
      setDuration('');
      setAmount('');
      setFirstname('');
      setLastname('');
      setAddress('');
      setPincode('');
      setAadharCard(null);
      setInstallmentType('');
      setGuardianName(''); // Clear new field
      setPanNo(''); // Clear new field
      setBankAcNo(''); // Clear new field
      setNominiee(''); // Clear new field
      setRelationOfNominiee(''); // Clear new field
      setDateOfBirth(''); // Clear new field
      setProfilePicture(null); // Clear new field
      setProfilePicturePreview(null); // Clear new field
      setGranter(''); // Clear new field
      setError('');
    } catch (error) {
      setError(error.message);
      setSuccessMessage('');
    }
  };

  const handleDownloadForm = () => {
    if (loanId) {
      navigate(`/download-form/${loanId}`);
    }
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    setProfilePicture(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfilePicturePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="apply-for-loan-page">
      <h2>Apply for Loan</h2>
      {error && (
        <div className="message error">
          <FaExclamationCircle className="message-icon" />
          <p>{error}</p>
        </div>
      )}
      {successMessage && (
        <div className="message success">
          <FaCheckCircle className="message-icon" />
          <p>{successMessage}</p>
          <button onClick={handleDownloadForm}>Download Form</button>
        </div>
      )}
      {!successMessage && (
        <form className="loan-form" onSubmit={handleSubmit} encType="multipart/form-data">
          {profilePicturePreview && (
            <div className="profile-picture-preview">
              <img src={profilePicturePreview} alt="Profile Preview" />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="firstname">First Name:</label>
            <input
              type="text"
              id="firstname"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastname">Last Name:</label>
            <input
              type="text"
              id="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="guardianName">Guardian Name:</label>
            <input
              type="text"
              id="guardianName"
              value={guardianName}
              onChange={(e) => setGuardianName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="pincode">Pincode:</label>
            <input
              type="text"
              id="pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="dateOfBirth">Date of Birth:</label>
            <input
              type="date"
              id="dateOfBirth"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
          </div>


          <div className="form-group">
            <label htmlFor="loanType">Loan Type:</label>
            <select
              id="loanType"
              value={loanType}
              onChange={(e) => setLoanType(e.target.value)}
              required
            >
              <option value="">Select Loan Type</option>
              <option value="Home Loan">Home Loan</option>
              <option value="Car Loan">Car Loan</option>
              <option value="Personal Loan">Personal Loan</option>
              <option value="Business Loan">Business Loan</option>
            </select>
          </div>
          <input
            type="hidden"
            id="interestRate"
            value={interestRate}
          />
          <div className="form-group">
            <label htmlFor="duration">Duration (months):</label>
            <select
              id="duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            >
              <option value="">Select Duration</option>
              <option value="1">1 month</option>
              <option value="2">2 months</option>
              <option value="3">3 months</option>
              <option value="4">4 months</option>
              <option value="5">5 months</option>
              <option value="6">6 months</option>
              <option value="7">7 months</option>
              <option value="8">8 months</option>
              <option value="9">9 months</option>
              <option value="10">10 months</option>
              <option value="11">11 months</option>
              <option value="12">12 months</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount in ₹ (upto 1 Lakh):</label>
            <input
              type="text"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="aadharCard">Aadhar Card:</label>
            <input
              type="file"
              id="aadharCard"
              onChange={(e) => setAadharCard(e.target.files[0])}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="installmentType">Installment Type:</label>
            <select
              id="installmentType"
              value={installmentType}
              onChange={(e) => setInstallmentType(e.target.value)}
              required
            >
              <option value="">Select Installment Type</option>
              <option value="monthly">Monthly</option>
              <option value="daily">Daily</option>
            </select>
          </div>
         
         
          <div className="form-group">
            <label htmlFor="panNo">PAN Number:</label>
            <input
              type="text"
              id="panNo"
              value={panNo}
              onChange={(e) => setPanNo(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="bankAcNo">Bank Account Number:</label>
            <input
              type="text"
              id="bankAcNo"
              value={bankAcNo}
              onChange={(e) => setBankAcNo(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="nominiee">Nominee:</label>
            <input
              type="text"
              id="nominiee"
              value={nominiee}
              onChange={(e) => setNominiee(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="relationOfNominiee">Relation of Nominee:</label>
            <input
              type="text"
              id="relationOfNominiee"
              value={relationOfNominiee}
              onChange={(e) => setRelationOfNominiee(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="profilePicture">Profile Picture:</label>
            <input
              type="file"
              id="profilePicture"
              onChange={handleProfilePictureChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="Granter">Granter(Optional):</label>
            <input
              type="text"
              id="Granter"
              value={Granter}
              onChange={(e) => setGranter(e.target.value)}
            />
          </div>

          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default ApplyForLoan;
