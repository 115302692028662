import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import Navbar from './components/navbar/Navbar';
import Dashboard from './pages/dashboard/Dashboard';
import ApplyForLoan from './pages/applyforloan/ApplyForLoan';
import LoanSummery from './pages/loansummery/LoanSummary';
import AuthPage from './pages/auth/AuthPage';
import './App.css';
import DownloadForm from './pages/loansummery/DownloadForm';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if shristi_user_id exists in localStorage
    const userId = localStorage.getItem('shristi_user_id');
    if (userId) {
      setIsLoggedIn(true);
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <div className="App">
        {isLoggedIn ? (
          <>
            <Navbar toggleSidebar={toggleSidebar} />
            <div className={`main-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
              <Sidebar isSidebarOpen={isSidebarOpen} />
              <div className="content">
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/apply-for-loan" element={<ApplyForLoan />} />
                  <Route path="/loan-summary" element={<LoanSummery />} />
                  <Route path="/authpage" element={<Navigate to="/dashboard" />} />
                  <Route path="/" element={<Navigate to="/dashboard" />} />

                  <Route path="/download-form/:id" element={<DownloadForm />} /> {/* New Route */}
      
                </Routes>
              </div>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/authpage" element={<AuthPage />} />
            <Route path="*" element={<Navigate to="/authpage" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
