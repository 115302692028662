import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaRegChartBar } from 'react-icons/fa';
import './Sidebar.css';
import { BiCreditCard } from 'react-icons/bi';

function Sidebar({ isSidebarOpen }) {
  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <ul>


        <li>
          <Link to="/dashboard">
            <FaHome /><span>Dashboard</span>
          </Link>
        </li>


        <li>
          <Link to="/apply-for-loan">
           <BiCreditCard/><span>Apply For Loan</span>
          </Link>
        </li>

        <li>
          <Link to="/loan-summary">
            <FaRegChartBar/><span>Loan Summary</span>
          </Link>
        </li>

       
      </ul>
    </div>
  );
}

export default Sidebar;
