import React, { useEffect, useState } from 'react';
import { BiCreditCard } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const [loans, setLoans] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [installments, setInstallments] = useState([]);
  const [collections, setCollections] = useState([]);
  const [overdueNotification, setOverdueNotification] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLoans = async () => {
      const userId = localStorage.getItem('shristi_user_id');
      if (!userId) {
        console.error('User ID not found in local storage');
        return;
      }

      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/user/${userId}`);
        const data = await response.json();
        setLoans(data);
        await checkAllOverdueInstallments(data); // Check for overdue installments after fetching loans
      } catch (error) {
        console.error('Error fetching loans:', error);
      }
    };

    fetchLoans();
  }, []);

  const fetchCollections = async (loanId) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/collections/loan/${loanId}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching collections:', error);
      return [];
    }
  };

  const calculateInstallments = async (loan) => {
    const { id, duration, amount, interest_rate, updated_at, installment_type } = loan;
    const collectionsData = await fetchCollections(id);
    setCollections(collectionsData);

    const installments = [];
    const startDate = new Date(updated_at);

    if (installment_type === 'monthly') {
      const monthlyInterestRate = interest_rate / 100 / 12;
      const monthlyInstallment = (amount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -duration));

      for (let i = 0; i < duration; i++) {
        const installmentDate = new Date(startDate);
        installmentDate.setMonth(startDate.getMonth() + i);
        const installmentDateString = installmentDate.toLocaleDateString();
        const collectionOnDate = collectionsData.find(collection => new Date(collection.collection_date).toLocaleDateString() === installmentDateString);

        const status = collectionOnDate ? 'Submitted' : 'Pending';
        const today = new Date();
        const isOverdue = new Date(installmentDate) < today && status === 'Pending';

        installments.push({
          date: installmentDateString,
          amount: monthlyInstallment.toFixed(2),
          status: isOverdue ? 'Overdue' : status
        });
      }
    } else if (installment_type === 'daily') {
      const totalDays = duration * 30;
      const dailyInterestRate = interest_rate / 100 / 365;
      const dailyInstallment = (amount * dailyInterestRate) / (1 - Math.pow(1 + dailyInterestRate, -totalDays));

      for (let i = 0; i < totalDays; i++) {
        const installmentDate = new Date(startDate);
        installmentDate.setDate(startDate.getDate() + i);
        const installmentDateString = installmentDate.toLocaleDateString();
        const collectionOnDate = collectionsData.find(collection => new Date(collection.collection_date).toLocaleDateString() === installmentDateString);

        const status = collectionOnDate ? 'Submitted' : 'Pending';
        const today = new Date();
        const isOverdue = new Date(installmentDate) < today && status === 'Pending';

        installments.push({
          date: installmentDateString,
          amount: dailyInstallment.toFixed(2),
          status: isOverdue ? 'Overdue' : status
        });
      }
    }

    setInstallments(installments);
    setSelectedLoan(loan); // Set selected loan to open the modal
  };

  const checkAllOverdueInstallments = async (loans) => {
    let hasOverdue = false;

    for (const loan of loans) {
      const collectionsData = await fetchCollections(loan.id);
      const startDate = new Date(loan.updated_at);
      const today = new Date();

      if (loan.installment_type === 'monthly') {
        const monthlyInterestRate = loan.interest_rate / 100 / 12;
        const monthlyInstallment = (loan.amount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -loan.duration));

        for (let i = 0; i < loan.duration; i++) {
          const installmentDate = new Date(startDate);
          installmentDate.setMonth(startDate.getMonth() + i);
          const installmentDateString = installmentDate.toLocaleDateString();
          const collectionOnDate = collectionsData.find(collection => new Date(collection.collection_date).toLocaleDateString() === installmentDateString);

          const status = collectionOnDate ? 'Submitted' : 'Pending';
          if (new Date(installmentDate) < today && status === 'Pending') {
            hasOverdue = true;
          }
        }
      } else if (loan.installment_type === 'daily') {
        const totalDays = loan.duration * 30;
        const dailyInterestRate = loan.interest_rate / 100 / 365;
        const dailyInstallment = (loan.amount * dailyInterestRate) / (1 - Math.pow(1 + dailyInterestRate, -totalDays));

        for (let i = 0; i < totalDays; i++) {
          const installmentDate = new Date(startDate);
          installmentDate.setDate(startDate.getDate() + i);
          const installmentDateString = installmentDate.toLocaleDateString();
          const collectionOnDate = collectionsData.find(collection => new Date(collection.collection_date).toLocaleDateString() === installmentDateString);

          const status = collectionOnDate ? 'Submitted' : 'Pending';
          if (new Date(installmentDate) < today && status === 'Pending') {
            hasOverdue = true;
          }
        }
      }
    }

    setOverdueNotification(hasOverdue);
  };

  const closeModal = () => {
    setSelectedLoan(null);
    setInstallments([]);
  };

  const handleClickOutside = (e) => {
    if (e.target.className === 'modal') {
      closeModal();
    }
  };

  const handleDownloadForm = (id) => {
    navigate(`/download-form/${id}`);
  };

  const handleApplyForLoan = () => {
    navigate('/apply-for-loan');
  };

  return (
    <div className="dashboard">
      {overdueNotification && (
        <div className="notification">
          <p>🔴 You have overdue installments. Please check your loan summary for details.</p>
        </div>
      )}
      <h2>Welcome to User Dashboard</h2>

      <div className="loan-services">
        <h3>Our Loan Services</h3>
        <div className="service-cards">
          <div className="card">
            <h4>Home Loans</h4>
            <p>Get the best deals for home loans with flexible repayment options and low-interest rates.</p>
          </div>
          <div className="card">
            <h4>Personal Loans</h4>
            <p>Secure personal loans for various needs, including travel, education, and more.</p>
          </div>
          <div className="card">
            <h4>Business Loans</h4>
            <p>Finance your business growth with our specially designed business loan packages.</p>
          </div>
          <div className="card">
            <h4>Education Loans</h4>
            <p>Achieve your academic goals with our easy and accessible education loans.</p>
          </div>
        </div>
      </div>

      {loans.length === 0 ? (
        <div className="apply-for-loan-banner">
          <p>No loan records found. <button onClick={handleApplyForLoan}>Apply for a Loan</button></p>
        </div>
      ) : (
        <div className="loan-requests">
          <table>
            <thead>
              <tr>
                <th>Loan ID</th>
                <th>Loan Type</th>
                <th>Interest Rate</th>
                <th>Duration</th>
                <th>Amount</th>
                <th>Approval Status</th>
                <th>Posted At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loans.map((loan) => (
                <tr key={loan.id}>
                  <td>#{loan.id}</td>
                  <td>{loan.loan_type}</td>
                  <td>{loan.interest_rate}%</td>
                  <td>{loan.duration} months</td>
                  <td>₹{loan.amount}</td>
                  <td className={loan.approval_status === 'pending' ? 'status-pending' : 'status-accepted'}>
                    {loan.approval_status}
                  </td>
                  <td>{new Date(loan.posted_at).toLocaleDateString()}</td>
                  <td>
                    {loan.approval_status === 'approved' && (
                      <button onClick={() => calculateInstallments(loan)}>
                        <BiCreditCard /> Loan Summary
                      </button>
                    )}
                    <button onClick={() => handleDownloadForm(loan.id)}>Download Form</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {selectedLoan && (
        <div className="modal" onClick={handleClickOutside}>
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <h3>Installment Details</h3>
            <table>
              <thead>
                <tr>
                  <th>Sl. NO</th>
                  <th>Installment Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {installments.map((installment, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{installment.date}</td>
                    <td>₹{installment.amount}</td>
                    <td className={`status-${installment.status.toLowerCase()}`}>{installment.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
