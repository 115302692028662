import React, { useState } from 'react';
import './AuthPage.css';

const AuthPage = () => {
    const [activeTab, setActiveTab] = useState('login');
    const [loginData, setLoginData] = useState({ username: '', password: '' });
    const [signupData, setSignupData] = useState({ username: '', password: '', email: '', firstname: '', lastname: '', address: '' });
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setMessage('');
    };

    const handleInputChange = (e, isLogin) => {
        const { name, value } = e.target;
        if (isLogin) {
            setLoginData({ ...loginData, [name]: value });
        } else {
            setSignupData({ ...signupData, [name]: value });
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch('https://srishticooperativesociety.com/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });
            const data = await response.json();

            if (response.ok) {
                localStorage.setItem('shristi_user_id', data.id);
                window.location.reload(); // Refresh the page after login
            } else {
                setMessage(`Login failed: ${data.message || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Login error:', error);
            setMessage('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch('https://srishticooperativesociety.com/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(signupData),
            });

            const responseText = await response.text();
            console.log('Response:', responseText);

            if (response.ok) {
                const data = JSON.parse(responseText);
                setMessage('Signup successful!');
                setTimeout(() => alert('Signup successful!'), 100); // Popup message
            } else {
                try {
                    const errorData = JSON.parse(responseText);
                    setMessage(`Signup failed: ${errorData.message || 'Unknown error'}`);
                } catch (jsonError) {
                    setMessage('Signup failed: Invalid response format');
                }
            }
        } catch (error) {
            console.error('Signup error:', error);
            setMessage('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <h1>Welcome to Shristi Cooperative Society</h1>
            <div className="tabs">
                <button onClick={() => handleTabClick('login')} className={activeTab === 'login' ? 'active' : ''}>Login</button>
                <button onClick={() => handleTabClick('signup')} className={activeTab === 'signup' ? 'active' : ''}>Signup</button>
            </div>
            {loading && <div className="loader">Loading...</div>}
            {activeTab === 'login' ? (
                <form onSubmit={handleLogin}>
                    <div>
                        <label>Username: </label>
                        <input
                            type="text"
                            name="username"
                            value={loginData.username}
                            onChange={(e) => handleInputChange(e, true)}
                            required
                        />
                    </div>
                    <div>
                        <label>Password: </label>
                        <input
                            type="password"
                            name="password"
                            value={loginData.password}
                            onChange={(e) => handleInputChange(e, true)}
                            required
                        />
                    </div>
                    <button type="submit">Login</button>
                </form>
            ) : (
                <form onSubmit={handleSignup}>
                    <div>
                        <label>Username: </label>
                        <input
                            type="text"
                            name="username"
                            value={signupData.username}
                            onChange={(e) => handleInputChange(e, false)}
                            required
                        />
                    </div>
                    <div>
                        <label>Password: </label>
                        <input
                            type="password"
                            name="password"
                            value={signupData.password}
                            onChange={(e) => handleInputChange(e, false)}
                            required
                        />
                    </div>
                    <div>
                        <label>First Name: </label>
                        <input
                            type="text"
                            name="firstname"
                            value={signupData.firstname}
                            onChange={(e) => handleInputChange(e, false)}
                            required
                        />
                    </div>
                    <div>
                        <label>Last Name: </label>
                        <input
                            type="text"
                            name="lastname"
                            value={signupData.lastname}
                            onChange={(e) => handleInputChange(e, false)}
                            required
                        />
                    </div>
                    <div>
                        <label>Address: </label>
                        <input
                            type="text"
                            name="address"
                            value={signupData.address}
                            onChange={(e) => handleInputChange(e, false)}
                            required
                        />
                    </div>
                    <div>
                        <label>Email: </label>
                        <input
                            type="email"
                            name="email"
                            value={signupData.email}
                            onChange={(e) => handleInputChange(e, false)}
                            required
                        />
                    </div>
                    <button type="submit">Signup</button>
                </form>
            )}
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default AuthPage;
