import React from 'react';
import { FaBars } from 'react-icons/fa';
import './Navbar.css';

function Navbar({ toggleSidebar }) {

  const handleLogout = () => {
    // Remove shristi_user_id from localStorage
    localStorage.removeItem('shristi_user_id');
    window.location.reload();
  };

  return (
    <div className="navbar">
      <FaBars className="menu-icon" onClick={toggleSidebar} />
      <h1>User Account</h1>
      <div className="user-info">
        {/* <img
          src="https://cdn-icons-png.freepik.com/512/147/147142.png"
          alt="profile"
          className="profile-pic"
        /> */}
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default Navbar;
